<template>
  <CyModal
    :header-title="$t('header')"
    :loading="loading"
    :action-btn-func="deleteResource"
    :cancel-btn-func="cancel"
    dialog-class="resource-delete-dialog"
    modal-type="delete">
    <i18n
      path="subtitle"
      tag="p">
      <template #name>
        <span class="font-weight-bold">{{ resource.name }}</span>
      </template>
    </i18n>
  </CyModal>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'CyInventoryResourceDelete',
  props: {
    resource: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapState('organization/resource', {
      errors: (state) => state.errors.delete,
    }),
  },
  methods: {
    ...mapActions('organization/resource', [
      'DELETE_RESOURCE',
    ]),
    async deleteResource () {
      const resourceId = this.resource.id

      this.$toggle.loading(true)
      await this.DELETE_RESOURCE({ resourceId })
      this.$toggle.loading(false)

      if (!_.isEmpty(this.errors)) this.$emit('error')
      else this.$emit('success')
    },
    cancel () {
      this.$emit('cancel')
    },
  },
  i18n: {
    messages: {
      en: {
        header: 'Delete @:resource?',
        subtitle: 'The {name} resource is going to be deleted.',
      },
      es: {
        header: 'Eliminar @:resource?',
        subtitle: 'El recurso {name} se eliminará.',
      },
      fr: {
        header: 'Supprimer la @:resource ?',
        subtitle: `La ressource {name} va être supprimée.`,
      },
    },
  },
}
</script>
